import * as React from "react";
import { YearInput } from 'semantic-ui-calendar-react';
import { Header, Dropdown, Grid, Segment, Button } from "semantic-ui-react";

interface MonthPickerProps {
  isLoading: boolean,
  title: string,
  monatsFilter: number
  setMonatsFilter: React.Dispatch<React.SetStateAction<number>>
  jahresFilter: number
  setJahresFilter: React.Dispatch<React.SetStateAction<number>>
  showAll?: boolean
  setShowAll: React.Dispatch<React.SetStateAction<boolean>>
  showFilter?: boolean
}

export const MonthPicker: React.FC<MonthPickerProps> = ({ monatsFilter, setMonatsFilter, jahresFilter, setJahresFilter, showAll, setShowAll, showFilter, ...props }) => {

  console.log(showFilter);
    const filterOptions = [
        { key: 1, text: 'Jänner', value: 1 },
        { key: 2, text: 'Februar', value: 2 },
        { key: 3, text: 'März', value: 3 },
        { key: 4, text: 'April', value: 4 },
        { key: 5, text: 'Mai', value: 5 },
        { key: 6, text: 'Juni', value: 6 },
        { key: 7, text: 'Juli', value: 7 },
        { key: 8, text: 'August', value: 8 },
        { key: 9, text: 'September', value: 9 },
        { key: 10, text: 'Oktober', value: 10 },
        { key: 11, text: 'November', value: 11 },
        { key: 12, text: 'Dezember', value: 12 }
    ];
  const gridWith = showFilter ? '1' : '2';
    const monatsAuswahl = () => {
        return (
            <>
                <Header as="h5" style={{ textAlign: 'center' }}>Monat</Header>
                <Dropdown
                    onChange={(e, { value }) => {
                        value && setMonatsFilter(value as number);
                    }}
                    value={monatsFilter}
                    fluid
                    selection
                    options={filterOptions}
                />
            </>
        );
    };
    const jahresAuswahl = () => {
        return (
            <>
                <Header as="h5" style={{ textAlign: 'center' }}>Jahr</Header>
                <YearInput
                    value={jahresFilter.toString()}
                    clearable={true}
                    onClear={() => setJahresFilter(new Date().getFullYear())}
                    onChange={(e, data) => {
                        setJahresFilter(new Date(data.value).getFullYear());
                    }}
                    fluid
                />
            </>
        );
    };
  const showAllAuswahl = () => {
    return (
      <>
        <Header as="h5" >Alle anzeigen</Header>
        <Button
          className={'monthpicker-button'}
          icon={showAll ? 'eye' : 'eye slash'}
          primary
          onClick={() => setShowAll(!showAll)}
        /> 
      </>
    )

  }
    function monatWechseln(vor: boolean) {
        let oldYear = jahresFilter;
        let oldMonth = monatsFilter;

        if (vor && oldMonth === 12) {
            setMonatsFilter(1);
            setJahresFilter(oldYear + 1);
        }
        else if (!vor && oldMonth === 1) {
            setMonatsFilter(12);
            setJahresFilter(oldYear - 1);
        }
        else {
            setMonatsFilter(oldMonth + 1 * (vor ? 1 : -1));
        }
    }

    return (
        <Segment loading={props.isLoading}>
            <h3 style={{ textAlign: 'center' }}>
                <Button key={`ButtonLeft`} className={"prev-next"} icon={"angle left"} primary type={'button'} data-tooltip={"Zum vorherigen Monat wechseln"} disabled={props.isLoading}
                    onClick={() => { monatWechseln(false); }} /> &nbsp;
                {props.title}
                &nbsp; <Button key={`ButtonRight`} className={"prev-next"} icon={"angle right"} primary type={'button'} data-tooltip={"Zum nächsten Monat wechseln"} disabled={props.isLoading}
                    onClick={() => { monatWechseln(true); }} /> &nbsp;
            </h3>
            <Grid stackable={false}>
                <Grid.Column width="1"></Grid.Column>
                <Grid.Column width="6">
                    {React.createElement(jahresAuswahl, [])}
                </Grid.Column>
          <Grid.Column width={gridWith}></Grid.Column>
                <Grid.Column width="6">
                    {React.createElement(monatsAuswahl, [])}
          </Grid.Column>
          {showFilter ? <Grid.Column width="2"> {React.createElement(showAllAuswahl, [])}</Grid.Column> : <Grid.Column width="1"></Grid.Column>}               
            </Grid>
        </Segment>
    );
};