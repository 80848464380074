import * as React from 'react';
import { useQuery } from 'react-query';
import MitarbeiterApi from '../../api/MitarbeiterApi';
import { Grid, Segment } from 'semantic-ui-react';
import { AbwesenheitsModel } from '../../generated/models/AbwesenheitsModel';
import { MobileAbwesenheitsUebersichtModel } from '../../generated/models/MobileAbwesenheitsUebersichtModel';
import { MonthPicker } from '../MonthPicker';
import useDocumentTitle, { DOCUMENT_TITLES } from '../../hooks/useDocumentTitle';
import clsx from 'clsx';
import { LegendImages, Legende } from '../Legende';


interface AbwesenheitsuebersichtMProps {
}

export const AbwesenheitsuebersichtM: React.FC<AbwesenheitsuebersichtMProps> = (props) => {
  useDocumentTitle(DOCUMENT_TITLES.abwesenheitsuebersicht)
  const startFilter = new Date();

  const [monatsFilter, setMonatsFilter] = React.useState((startFilter.getMonth() + 1)); // Jänner Monat wäre 0 etc
  const [jahresFilter, setJahresFilter] = React.useState(startFilter.getFullYear());
  const [showAll, setShowAll] = React.useState(false);

  React.useEffect(() => {
    if (localStorage && typeof localStorage.getItem('abwesenheiten-showAll') === "string") {
      setShowAll(JSON.parse(localStorage.getItem('abwesenheiten-showAll') as string))
    }
  }, [setShowAll]);

  React.useEffect(() => {
    localStorage.setItem('abwesenheiten-showAll', showAll ? "true" : "false")
  }, [showAll]);

  const abwesenheitsUebersicht = useQuery(["getMobileAbwesenheitsuebersichtGET", jahresFilter, monatsFilter, showAll], () =>
    MitarbeiterApi.getMobileAbwesenheitsuebersichtGET({ jahr: jahresFilter, monat: monatsFilter, showAll: showAll })
  );

  const legendImages: LegendImages[] | undefined = React.useMemo(() => {
    return abwesenheitsUebersicht.data?.tagesstempel?.map((stempel, i) => {
      return {
        backgroundImage: `url(data:${stempel.fileContentType};base64,${stempel.fileContent})`,
        content: stempel.name ?? "Tagesstempel"
      }
    })
  }, [abwesenheitsUebersicht.data?.tagesstempel]);

  const filterOptions = [
    { key: 1, text: 'Jänner', value: 1 },
    { key: 2, text: 'Februar', value: 2 },
    { key: 3, text: 'März', value: 3 },
    { key: 4, text: 'April', value: 4 },
    { key: 5, text: 'Mai', value: 5 },
    { key: 6, text: 'Juni', value: 6 },
    { key: 7, text: 'Juli', value: 7 },
    { key: 8, text: 'August', value: 8 },
    { key: 9, text: 'September', value: 9 },
    { key: 10, text: 'Oktober', value: 10 },
    { key: 11, text: 'November', value: 11 },
    { key: 12, text: 'Dezember', value: 12 }
  ];
  let monat = filterOptions.find(f => f.value === monatsFilter)?.text ?? monatsFilter;
  return (
    <>
      <MonthPicker
        isLoading={abwesenheitsUebersicht.isLoading}
        monatsFilter={monatsFilter}
        setMonatsFilter={setMonatsFilter}
        jahresFilter={jahresFilter}
        setJahresFilter={setJahresFilter}
        showAll={showAll}
        setShowAll={setShowAll}
        title="Abwesenheitsübersicht">
      </MonthPicker>
      {abwesenheitsUebersicht && !abwesenheitsUebersicht.isLoading && abwesenheitsUebersicht.data &&
        abwesenheitsUebersicht.data.mobileAnsicht && abwesenheitsUebersicht.data.mobileAnsicht.length > 0 ?
        <>
          <div>
            {abwesenheitsUebersicht.data!.mobileAnsicht!.map((m: MobileAbwesenheitsUebersichtModel) => {
              return (
                <Segment className={clsx(Number(m.woche) === currentWeekNumber() && jahresFilter === startFilter.getFullYear() && monatsFilter === startFilter.getMonth() + 1 && "currentWeek")}>
                  <h3 style={{ textAlign: 'center' }}>Kalenderwoche {m.woche}</h3>
                  <Grid celled='internally'>
                    <Grid.Row>
                      <Grid.Column width="9">Mitarbeiter</Grid.Column>
                      {m.tage && m.tage.map(tag => <Grid.Column className={clsx('mobile-abwesenheit', (Number(tag) === new Date().getDate()) && "currentDay")} width="1">{tag}</Grid.Column>)}

                    </Grid.Row>
                    {m.abwesenheiten && m.abwesenheiten.map((abwesenheit: AbwesenheitsModel) => {
                      return (<Grid.Row> <Grid.Column width="9">{abwesenheit.nachname} {abwesenheit.vorname} {abwesenheit.personalNr && `(${abwesenheit.personalNr})`}</Grid.Column>
                        {
                          m.tage && m.tage.map((day, i) => {
                            day = day.length > 0 ? day : '0';
                            const dayI = (day as unknown as number - 1);
                            const numTagesstempel: number = abwesenheit.tagesstempel?.[dayI]?.length ?? 0;
                            const codeLen: number = abwesenheit.codes?.[i]?.length ?? 0;
                            const code = codeLen > 0 ? abwesenheit!.codes![i]! : '';
                            return <Grid.Column width="1" className={code ? `${code} mobile-abwesenheit` : 'mobile-abwesenheit'}
                            >
                              {numTagesstempel > 0 && (abwesenheit.tagesstempel?.[dayI]?.length ?? 0) > 0 &&
                              <div className="tagesstempel-container">
                                  {abwesenheit && abwesenheit.tagesstempel && abwesenheit.tagesstempel[dayI].map(stempelID => {
                                    const stempel = abwesenheitsUebersicht && abwesenheitsUebersicht.data && abwesenheitsUebersicht.data.tagesstempel
                                    && abwesenheitsUebersicht.data.tagesstempel.find(t => t.id === stempelID)
                                  return stempel && <img className="tagesstempel-img" src={`data:${stempel?.fileContentType};base64,${stempel?.fileContent}`} alt={stempel?.name ?? "Tagesstempel"} />
                                })}
                              </div>
                            }
                          </Grid.Column>
                        })}</Grid.Row>)
                    }
                    )}

                  </Grid>
                </Segment>)
            })}
          </div>
          <Segment>
            <Legende
              colors={[
                { css: "legend-box abwesend", content: "Genehmigte Abwesenheiten" },
                { css: "legend-box abwesend-offen", content: "Offene Abwesenheiten" },
                { css: "legend-box berufsschule", content: "Berufsschule / Karenz" },
                { css: "legend-box betriebsfrei", content: "Betriebsfrei / Feiertag" },
                { css: "legend-box halbertag", content: "Betriebsfrei / Feiertag (teilweise)" },
                { css: "legend-box kein-arbeitstag", content: "Frei" },
              ]}
              images={legendImages}
              mobileSolo={true}
            />
          </Segment>
        </>
        : <Segment hidden={abwesenheitsUebersicht.isLoading} > <h3>Noch keine Abwesenheiten für {monat} {jahresFilter} vorhanden.</h3> </Segment>
      }</>
  )
}

const currentWeekNumber = () => {
  const now = new Date();
  const firstOfJanuary = new Date(now.getFullYear(), 0, 1);
  const weekNumber = Math.ceil((((now.getTime() - firstOfJanuary.getTime()) / 86400000) + firstOfJanuary.getDay() + 1) / 7);
  return weekNumber
}