import * as React from 'react';
import { useQuery } from 'react-query';
import { Button, Card, Icon, Segment } from 'semantic-ui-react';
import  CommonApi  from '../api/CommonApi';
import useDocumentTitle, { DOCUMENT_TITLES } from '../hooks/useDocumentTitle';
import { VersionViewModel } from '../generated/models/VersionViewModel';

interface AboutProps {
}

export const Info: React.FC<AboutProps> = () => {
  useDocumentTitle(DOCUMENT_TITLES.info)
  const refreshPage = () => {
    window.location.reload();
  }
  const commonVersions = useQuery(["getVersionGET"],
    () => CommonApi.getVersionGET({clearCache: false}));

  return (
    <div>
      {commonVersions.data && !commonVersions.isLoading && commonVersions.data.isLoggedIn && commonVersions.data.isAdmin ? (
        <Card.Group>
               <Card
                header={commonVersions.data.produkt}
                meta={commonVersions.data.webAppVersion}
                description={[
                  commonVersions.data.unternehmen
                ].map(msg => (
                  <div key={msg ? msg : ""}>{msg}</div>
                ))}
              />
            <Card
            header="Host Environment"
            meta={commonVersions.data.hostVersion}
            description={[
              commonVersions.data.hostAppName,
              commonVersions.data.hostEnvironment
            ].map(msg => (
              <div key={msg ? msg : ""}>{msg}</div>
            ))}
          />
          <Card
            header="Utils"
            meta={commonVersions.data.utilsVersion}
            description="Businesslogik und Datenbank Schnittstelle"
          />
          <Card>
            <Card.Content>
            <Card.Header content="Datenbank"></Card.Header>
            <Card.Meta>
              {commonVersions.data.dbVersion}
              </Card.Meta>
            <Card.Description>
              {commonVersions.data.dbServer}
              <br />
              {commonVersions.data.dbName}
              </Card.Description>
              </Card.Content>
              {commonVersions.data.dbError && 
              <Card.Content style={{border: '1px solid red'}}>
              <Card.Header>
                Fehler  &nbsp;<Icon name='warning circle'> </Icon>
              </Card.Header>
              <Card.Description>
                {commonVersions.data.dbError}
                    </Card.Description>
                    </Card.Content>
              }
          </Card>           
            <Card
              header="Cache leeren"
              meta="Um Einstellungen aus der Verwaltung zu übernehmen."
              description={
                <Button
                  className='row-button'
                  onClick={() => { CommonApi.getVersionGET({ clearCache: true }); refreshPage() }}
                  icon="trash"
                  size="mini"
                  secondary
                  floated='right'
                />
              }
            />          

        </Card.Group>
      ) :        
        commonVersions.data && !commonVersions.isLoading && commonVersions.data.isLoggedIn ? <Card
            header={commonVersions.data.produkt}
            meta={commonVersions.data.webAppVersion}
            description={[
              commonVersions.data.unternehmen
            ].map(msg => (
              <div key={msg ? msg : ""}>{msg}</div>
            ))}
          />
        :    
          <Card
            header={`Login benötigt`}
            meta={`Loggen Sie sich bitte ein.`}
            description={`Sollte in LogIn nicht möglich sein \n kontaktieren Sie bitte den cool Timewatch support.`}         
          />
        }
       
      </div>
    )
}